import { z } from "zod";

import type { ParsedUrlQuery } from "querystring";

import { LooseBladeSchema } from "./blades";

export interface DatadogData {
  clientToken?: string | null;
  serviceName?: string | null;
  applicationId?: string | null;
}

const HreflangSchema = z.object({
  locale: z.string(),
  url: z.string(),
});

export type Hreflang = z.infer<typeof HreflangSchema>;

const AnalyticsSchema = z.object({
  contentId: z.string(),
  contentLocale: z.string(),
  contentCategory: z.string().optional(),
  version: z.number().optional(),
  rev: z.string().optional(),
  publishDate: z.string().refine((value) => !isNaN(Date.parse(value)), {
    message: "publishDate must be a valid date string",
  }),
});

const ThemeSchema = z.object({
  id: z.string(),
});

const IconSchema = z.object({
  icon: z.string(),
  size: z.string(),
});

export const PageSchema = z.object({
  id: z.string(),
  translationId: z.string(),
  locale: z.string(),
  url: z.string().url(),
  theme: ThemeSchema,
  blades: z.array(LooseBladeSchema),
  title: z.string().optional(),
  description: z.string().optional(),
  canonicalUrl: z.string().url().optional(),
  metaImage: z.record(z.unknown()).optional(),
  analytics: AnalyticsSchema.optional(),
  hreflangs: z.array(HreflangSchema).optional(),
  favicon: z.record(z.string(), z.array(IconSchema)).optional(),
  osanoPolicyId: z.string().optional(),
  gtmContainerId: z.string().optional(),
  warnings: z.array(z.string()).optional(),
});

export type Page = z.infer<typeof PageSchema>;

export interface PageProps {
  page: Page;
  environment: string;
  datadogData: DatadogData;
  shouldRenderAvailability?: boolean;
}

export interface PathProps extends ParsedUrlQuery {
  backend: "pcs" | "mock" | "s3";
  channel: string;
  locale: string;
  path: string[];
}

export const SitemapSchema = z.array(
  z.object({
    loc: z.string().optional(),
    lastMod: z.string().optional(),
    changeFreq: z.string().optional(),
    priority: z.string().optional(),
    hreflangs: z.array(HreflangSchema).optional(),
  })
);

export type Sitemap = z.infer<typeof SitemapSchema>;
